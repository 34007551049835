import '../css/app.css';
import './bootstrap';
import { type DefineComponent, createApp, h } from 'vue';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import VueSocialSharing from 'vue-social-sharing';
import { createInertiaApp } from '@inertiajs/vue3';
import filters from '@/filters';
import permissions from '@/mixins/permissions.js';
import translatable from '@/mixins/translatable.js';
import { VueQueryPlugin } from '@tanstack/vue-query';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { i18nVue } from 'laravel-vue-i18n';

const appName = import.meta.env.VITE_APP_NAME || 'Laravel';

createInertiaApp({
	// Client-side title
	title: (title) => (title !== appName ? (title ? `${appName} | ${title}` : appName) : title),
	resolve: async (name) => {
		const pages = import.meta.glob<DefineComponent>('./Pages/**/*.vue');
		return resolvePageComponent(`./Pages/${name}.vue`, pages);
	},
	setup({ el, App, props, plugin }) {
		// Mail template icons
		Object.values(import.meta.glob('../images/mail/**/*.{png,svg,jpg}', { eager: true, query: '?url' }));

		const captchaKey = props.initialPage.props.recaptcha_site_key as string;
		const application = createApp({ render: () => h(App, props) })
			.use(plugin)
			.use(i18nVue, {
				resolve: async (lang: string) => {
					const languages = import.meta.glob('../../lang/*.json', { eager: true });
					return await languages[`../../lang/${lang}.json`];
				},
			})
			.use(VueReCaptcha, { siteKey: captchaKey, loaderOptions: { autoHideBadge: true } })
			.use(VueSocialSharing)
			.mixin(translatable)
			.mixin(permissions)
			.use(VueQueryPlugin);

		application.config.globalProperties.$filters = filters;

		// Delete Page Props from DOM
		// https://github.com/inertiajs/inertia/discussions/695
		// https://github.com/inertiajs/inertia/discussions/372#discussioncomment-6052940
		if (import.meta.env.PROD) {
			delete (el as HTMLElement).dataset['page'];
		}

		application.mount(el);
	},
	progress: {
		// The delay after which the progress bar will appear, in milliseconds...
		delay: 250,
		// The color of the progress bar...
		color: '#29d',
		// Whether to include the default NProgress styles...
		includeCSS: true,
		// Whether the NProgress spinner will be shown...
		showSpinner: true,
	},
});

// TODO: In the end this should be `vue` not `@vue/runtime-core`
// But this fixes the issue with the global properties
// laravel-vue-i18n is breaking the types
// https://github.com/nuxt/nuxt/issues/28707#issuecomment-2316806534
declare module '@vue/runtime-core' {
	interface ComponentCustomProperties {
		/** @deprecated Will be removed in the future, use direct import instead */
		$filters: typeof filters;
		/** @deprecated Will be removed in the future, use direct import instead */
		$localized: typeof translatable.methods.$localized;
		/** @deprecated Will be removed in the future, use direct import instead */
		$authenticated: typeof permissions.methods.$authenticated;
		/** @deprecated Will be removed in the future, use direct import instead */
		$role: typeof permissions.methods.$role;
		/** @deprecated Will be removed in the future, use direct import instead */
		$permission: typeof permissions.methods.$permission;
		/** @deprecated Will be removed in the future, use usePage hook instead */
		$page: any;
	}
}

declare module 'ziggy-js' {
	interface TypeConfig {
		strictRouteNames: true;
	}
}
